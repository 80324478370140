<template>
  <form @submit.prevent="submit">
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="firstname"
            :rules="nameRules"
            :counter="10"
            label="First name"
            required
            hide-details
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="lastname"
            :rules="nameRules"
            :counter="10"
            label="Last name"
            hide-details
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            hide-details
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            label="Password"
            hide-details
            required
            type="password"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="phone"
            :rules="phoneRules"
            label="Phone"
            hide-details
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn type="submit" color="primary" block class="mt-2">Register User</v-btn>
    </v-container>
  </form>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex'; 
import { useRouter } from 'vue-router'; 

export default {
  setup() {
    const store = useStore(); 
    const router = useRouter();
    const valid = ref(false);
    const firstname = ref('');
    const lastname = ref('');
    const email = ref('');
    const password = ref('');
    const phone = ref('');

    const nameRules = [
      value => {
        if (value) return true;
        return 'Name is required.';
      },
      value => {
        if (value?.length <= 10) return true;
        return 'Name must be less than 10 characters.';
      },
    ];

    const emailRules = [
      value => {
        if (value) return true;
        return 'E-mail is requred.';
      },
      value => {
        if (/.+@.+\..+/.test(value)) return true;
        return 'E-mail must be valid.';
      },
    ];

    const passwordRules = [
      value => {
        if (value) return true;
        return 'Pass is required.';
      },
      value => {
        if (value?.length >= 6) return true;
        return 'Password must be 6 or more characters.';
      },
    ];

    const phoneRules = [
      value => {
        if (value) return true;
        return 'Phone is required.';
      },
      value => {
        if (/^\d+$/.test(value)) return true;
        return 'Phone must be a valid number.';
      },
      value => {
        if (value?.length > 9) return true;
        return 'Password must be 9 or more characters.';
      },
    ];

    const submit = async () => {
      const data = {
        email: email.value,
        password: password.value,
        phone: phone.value,
        firstname: firstname.value,
        lastname: lastname.value,
      }
      try {
        await store.dispatch('registerUser', data);
        router.push({ name: 'login' });
      } catch (error) {
        console.error("Error en el registro:", error);
      }

    };

    return {
      valid,
      firstname,
      lastname,
      nameRules,
      email,
      emailRules,
      password,
      passwordRules,
      phone,
      phoneRules,
      submit,
    };
  },
};
</script>
