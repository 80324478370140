import store from '@/store';

export default function authMiddleware(to, from, next) {
  const isAuthenticated = store.state.loggedUser;

  if (!isAuthenticated) {
    next({ name: 'login' });
  } else {
    next();
  }
}
