import api from "../../api";
import { auth } from "../../firebase/firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import router from "@/router";
export default {
  async setUserAct({ commit, dispatch }, data) {
    const { email, password } = data;
    try {
      dispatch("showLoader");
      await new Promise((resolve) => {
        setTimeout(() => {
          console.log("ya hice el dispatch");
          resolve();
        }, 5000);
      });
      await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          console.log("🚀 ~ .then ~ user:", user);

          user
            .getIdToken(true)
            .then(async (idToken) => {
              let userAuth = await api.post("users/auth-with-token", {
                token: idToken,
              });
              if (userAuth) {
                commit("setUserMut", {
                  email: user.email,
                  uid: user.uid,
                  fullname: user.firstname + " " + user.lastname,
                });
                dispatch("hideLoader");
                router.push({ name: "user-takeaways" });
              }
            })
            .catch(function (error) {
              console.log("🚀 ~ Obtener token de acceso ~ error:", error);
              return false;
            });
        })
        .catch((error) => {
          if (error.code === "auth/invalid-credential") {
            dispatch("showSnackbar", "Invalid credentials");
            dispatch("hideLoader");
            console.log("Credenciales inválidas");
          }
          return false;
        });
    } catch (error) {
      dispatch("hideLoader");
      dispatch("showSnackbar", "Internal Error");
      console.error("Error en la solicitud:", error);
      return false;
    }
  },

  async logOut({ commit }) {
    try {
      commit("clearUserMut");
      // document.cookie = "theCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      await auth.signOut();
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  },

  async registerUser(context, data) {
    const { email, password, phone, firstname, lastname } = data;
    try {
      await api.post("users/register-user", {
        email: email,
        password: password,
        phone: phone,
        firstname: firstname,
        lastname: lastname,
      });
    } catch (error) {
      console.error("Error en la solicitud:", error);
      throw error;
    }
  },
};
