export const statusTakwaways = [
  "Not Started",
  "In progress",
  "Complete",
  "Cancelled",
  "On Hold",
  "DEV has questions",
  "UAT (User Acceptance Testing)",
  "Customer Follow-up Needed",
  "Waiting on Customer"
];

export const customers = [
  "NECI",
  "U1 - Construction (OSP)",
  "U1 - Wireless",
  "TEOPM - Internal",
  "VTG",
  "Ziply",
  "Core Geologic" 
];

export const projectName = [
  "AT&T Wireless",
  "Budget Control",
  "Crew Count",
  "CoreGeo - Architecture",
  "CoreGeo - Consulting",
  "CoreGeo - Ops - FieldServices",
  "CoreGeo - Ops - Geosteering",
  "CoreGeo - Sales",
  "CoreGeo - Accounting",
  "CoreGeo - IT",
  "CoreGeo - Safety",
  "Engineering",
  "Equipment Tracker",
  "Escalation Desk Tracker",
  "Escalation Sales Tracker",
  "F1 BackBone Tracker Construction",
  "Financial Portal",
  "Foreman",
  "GEAD (Compliance Dashboard)",
  "GeoCX",
  "HR Dashboard",
  "HR Employee Performance Review",
  "Inventory Control",
  "Investment Blocks & RabbitSales Int w/Smartsheet",
  "Isolved Int w/ Bamboo HR",
  "IssueTrak",
  "MDU BF",
  "MDU Green Field",
  "Natural Balanced",
  "NG Ziply Construction Tracker",
  "Onboarding",
  "Operations - TEOPM",
  "Operations - Marketing",
  "OSP - Constraint Tracker",
  "OSP - Damage Control",
  "OSP - New Billing WF",
  "OSP - Permitting",
  "OSP - PMO Dashboard",
  "OSP - Regional Trackers",
  "OSP - Inventory Control",
  "OSP - Master Plan",
  "OSP - Tool Tracker",
  "OSP - Production Amount Adjustment",
  "OSP - Safety",
  "OSP - Engineering Master Tracker",
  "Outages Tracker",
  "PO Tracker",
  "Production",
  "Production - Integration with GPM",
  "PTO or Vacation Time",
  "QA Agent",
  "QA Agent SMB",
  "QA Agent Annual Metrics",
  "Regional Restructure 2024",
  "Sales Tracker",
  "Talon Integration to SS",
  "Target - KPI OSP",
  "Team Meeting",
  "Tech Calendar",
  "Time Off - Tech Calendar",
  "Traffic Control",
  "Training / Certification",
  "TEOPM Internal - SMS Smartsheet",
  "TEOPM Internal - Support Email to Kambuchi Int",
  "TEOPM Internal - Time Tracker Kambuchi",
  "TEOPM Internal - Demo Material Creation",
  "TEOPM Internal - EOPS Website",
  "TEOPM Internal - Training & Certification",
  "VTG - Equipment Tracker",
  "VTG - Resource Management",
  "VTG - Inventory Control",
  "VTG - Barcode Integration",
  "Web Change Requests",
  "WFM",
  "WF Efficientization",
  "Wireless - Automated Payroll",
  "Wireless - Barcode Generator API",
  "Wireless - Construction",
  "Wireless - Cost Control",
  "Wireless - Foreman Forms",
  "Wireless - Inventory Control",
  "Wireless - Safety",
  "Wireless - Score Card",
  "Wireless - U1 Licensing",
  "Ziply QAQC",
  "Ziply Rewards MDU",
  "Ziply Rewards Submission",
  "Ziply Account Management"
];

export const requester = [
  {
    "name": "Dorin Gora",
    "email": "doring@utilitiesone.com"
  },
  {
      "name": "Igor Papo",
      "email": "igorp@utilitiesone.com"
  },
  {
      "name": "Charles",
      "email": "charlesh@utilitiesone.com"
  },
  {
      "name": "Kyle Clausen",
      "email": "kylec@utilitiesone.com"
  },
  {
      "name": "Valeria Tulbure",
      "email": "valeriat@utilitiesone.com"
  },
  {
      "name": "Kallie Ciano",
      "email": "kciano@vanirtg.com"
  },
  {
      "name": "Matt Stewart",
      "email": "mstewart@vanirtg.com"
  },
  {
      "name": "Erin Lawrence",
      "email": "erin.lawrence@ziply.com"
  },
  {
      "name": "Maksim Nelkin",
      "email": "mnelkin@coregeologic.com"
  },
  {
      "name": "Ali Sloan",
      "email": "asloan@coregeologic.com"
  },
  {
      "name": "James Hawkins",
      "email": "jhawkins@coregeologic.com"
  },
  {
      "name": "Paola Barlow",
      "email": "pbarlow@teopm.com"
  }
];

export const priority = ["1", "2", "3", "ZZZ"];

export const devAssigned = [
  {
    name: "Luis Aguilar",
    email: "luis@teopm.com"
  },
  {
    name: "Miguel Partida",
    email: "miguel.p@teopm.com"
  },
  {
    name: "Chuy Navarro",
    email: "chuy@teopm.com"
  },
  {
    name: "Karlo Huldong",
    email: "karlo@teopm.com"
  },
  {
    name: "Paola Barlow",
    email: "pbarlow@teopm.com"
  },
  {
    name: "Felix Acosta",
    email: "felix@teopm.com"
  },
  {
    name: "Chuma Favela",
    email: "chuma@teopm.com"
  },
  {
    name: "Cristina Abiera",
    email: "cristina@teopm.com"
  }
];
