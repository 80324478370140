import authMid from './authMid';
import { createRouter, createWebHistory } from "vue-router";
import TimeTracker from "@/views/TimeTracker.vue";
import RegisterUser from "@/views/RegisterUser.vue";
import LoginUser from "@/views/LoginUser.vue";
import UserTakeaways from "@/views/UserTakeaways.vue";
import NewTakeaways from "@/views/NewTakeaways.vue";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/register",
    name: "register",
    component: RegisterUser,
  },
  {
    path: "/login",
    name: "login",
    component: LoginUser,
  },
  {
    path: "/t-t",
    name: "t-t",
    component: TimeTracker,
    meta: { middleware: authMid }
  },
  {
    path: "/my-takeaways",
    name: "user-takeaways",
    component: UserTakeaways,
    meta: { middleware: authMid }
  },
  {
    path: "/add-takeaway",
    name: "add-takeaway",
    component: NewTakeaways,
    meta: { middleware: authMid }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    to.meta.middleware(to, from, next);
  } else {
    next();
  }
});

export default router;
