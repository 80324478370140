import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { db, auth } from "./firebase/firebaseConfig.js";
import { doc, onSnapshot } from "firebase/firestore";

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: {
        dark: false,
        variables: {},
        colors: {
          primary: "#176B87",
          secondary: "#86B6F6",
          accent: "#B4D4FF",
          success: "#EEF5FF",
          warning: "#BD4F6C",
        },
      },
    },
  },
});

const app = createApp(App);

app.use(router);
app.use(store);
app.use(vuetify);

let currentUser;
let userTimerDocRef;
let unsubscribeSnapshot;

const unsubscribeAuth = auth.onAuthStateChanged((user) => {
  if (user) {
    currentUser = auth.currentUser;
    userTimerDocRef = doc(db, "user-timer", currentUser.uid);

    store.commit("setUserMut", {
      uid: user.uid,
      email: user.email,
      fullname: user.firstname + " " + user.lastname,
    });
    store.commit("setAppLoaded", true);
    store.dispatch("getActiveTimer", user.uid);

    if (unsubscribeSnapshot) {
      unsubscribeSnapshot();
    }

    unsubscribeSnapshot = onSnapshot(userTimerDocRef, async (doc) => {
      console.log("Se produjo un cambio en el documento de Firestore main.js");
      if (doc.exists()) {
        const dataDoc = doc.data();
        
        if (dataDoc.isTimerActive && !store.state.tracker.isTimerActive) {
          
          store.dispatch("getActiveTimer", currentUser.uid);
        } else if (dataDoc.stop && dataDoc.currentAction === "stop") {
          store.commit("clearIsTimerActive");
        }
      }
    });
  } else {
    if (unsubscribeSnapshot) {
      unsubscribeSnapshot();
    }
    store.commit("clearUserMut");
    store.commit("setAppLoaded", true);
  }
});

app.mount("#app");

app.config.globalProperties.$destroy = unsubscribeAuth;
