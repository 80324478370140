export default {
  convertSeconds(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return { hours, minutes, seconds };
  },
  convertSecondsToDecimalHours(totalSeconds) {
    const decimalHours = totalSeconds / 3600;
    return decimalHours;
  },
  convertToDecimalHours(hours, minutes, seconds) {
    const decimalHours =
      parseInt(hours) + parseInt(minutes) / 60 + parseInt(seconds) / 3600;
    return decimalHours;
  },
};
