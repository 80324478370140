<template>
  <v-container>
    <v-row v-if="!loading">
      <v-col cols="12" md="6">
        <v-row>
          <v-select
            v-if="!isOtherProjectSelected"
            v-model="projectNameOption"
            :items="projectNames"
            label="Project Name"
            outlined
            required
            :disabled="isOtherProjectSelected"
          ></v-select>
          <v-checkbox
            v-model="isOtherProjectSelected"
            label="Other"
            color="blue"
            class="mt-3"
          ></v-checkbox>
          <v-text-field
            v-if="isOtherProjectSelected"
            v-model="otherProjectName"
            label="Enter Other Project Name"
            outlined
            required
            class="mt-3"
          ></v-text-field>
        </v-row>
        <div v-if="selectedProject">
          <h1
            v-if="hasTimeLeft"
            style="color: rgb(var(--v-theme-primary)) !important"
          >
            You have
            <span style="color: red">{{ formattedTime }}</span> hours left for
            this project
          </h1>
          <h1
            v-else-if="hasTimeAllocation"
            style="color: rgb(var(--v-theme-primary)) !important"
          >
            You have
            <span style="color: red">{{ formattedAllocation }}</span> hours
            allocated for this project
          </h1>
          <h1 v-else style="color: rgb(var(--v-theme-primary)) !important">
            This project does not have allocated hours
          </h1>
        </div>
        <v-text-field
          v-if="selectedProject && !isOtherProjectSelected"
          v-model="selectedProject.customer"
          label="Customer"
          outlined
          readonly
        ></v-text-field>
        <v-text-field
          v-if="selectedProject && !isOtherProjectSelected"
          v-model="selectedProject.contact"
          label="Contact"
          outlined
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-checkbox
          v-model="workingToday"
          color="blue"
          label="Working on today?"
          required
        ></v-checkbox>
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="activityDescription"
          label="Activity Description"
          outlined
          rows="3"
          auto-grow
          required
        ></v-textarea>
      </v-col>
      <v-col cols="12" md="6">
        <div class="date-picker">
          <v-date-picker
            color="primary"
            v-model="dateRequested"
            label="Date Requested"
            outlined
            required
          ></v-date-picker>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          v-model="statusOption"
          :items="statusTakwaways"
          label="Status"
          outlined
          required
        ></v-select>
        <v-col cols="12" md="12" v-if="statusOption === 'Complete'">
          <v-row class="d-flex align-center">
            <v-col cols="4">
              <v-text-field
                v-model="hoursElapsed"
                label="Hours Elapsed"
                outlined
                required
                @input="validateNumericInput('hoursElapsed')"
                @blur="validateHours"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="minutesElapsed"
                label="Minutes Elapsed"
                outlined
                required
                @input="validateNumericInput('minutesElapsed')"
                @blur="validateMinutes"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="secondsElapsed"
                label="Seconds Elapsed"
                outlined
                required
                @input="validateNumericInput('secondsElapsed')"
                @blur="validateSeconds"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <br />
        <v-select
          v-model="priorityOption"
          :items="priority"
          label="Priority"
          outlined
          required
        ></v-select>
        <br /><br />
        <v-select
          v-model="devAssignedOption"
          :items="teammates"
          label="Dev Assigned"
          item-title="name"
          item-value="name"
          return-object
          outlined
          required
        ></v-select>
        <br /><br /><br /><br />
        <v-btn
          :disabled="!allFieldsCompleted"
          @click="addTakeawayTemplate"
          color="secondary"
        >
          <span color="white">Add Takeaway</span>
        </v-btn>
      </v-col>
    </v-row>
    <CircularLoader v-else></CircularLoader>
  </v-container>
</template>
  
<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import CircularLoader from "./CircularLoader.vue";
import {
  statusTakwaways,
  customers,
  projectName,
  requester,
  priority,
  devAssigned,
} from "../const";

export default {
  components: {
    CircularLoader,
  },
  setup() {
    const store = useStore();
    const projectNameOption = ref(null);
    const isOtherProjectSelected = ref(false);
    const otherProjectName = ref("");
    const activityDescription = ref("");
    const workingToday = ref(false);
    const dateRequested = ref(new Date());
    const statusOption = ref("Not Started");
    const priorityOption = ref(null);
    const devAssignedOption = ref(null);
    const loading = ref(false);
    const hoursElapsed = ref(null);
    const minutesElapsed = ref(null);
    const secondsElapsed = ref(null);
    const selectedProject = ref(null);
    const projectNames = computed(() => {
      return store.state.project.projects.map((project) => project.projectName);
    });
    const teammates = computed(() => {
      return store.state.project.teammates.map((teammate) => teammate.teammate);
    });

    watch(projectNameOption, (newProjectName) => {
      if (newProjectName !== null) {
        selectedProject.value = store.state.project.projects.find(
          (project) => project.projectName === newProjectName
        );
      } else {
        selectedProject.value = null;
      }
    });

    function validateNumericInput(field) {
      this[field] = this[field].replace(/\D/g, "");
    }
    const hasTimeLeft = computed(
      () => selectedProject.value.timeLeft !== undefined
    );
    const hasTimeAllocation = computed(
      () => selectedProject.value.timeAllocation !== undefined
    );

    const formattedTime = computed(() => {
      if (
        selectedProject.value &&
        selectedProject.value.timeLeft !== null &&
        selectedProject.value.timeLeft !== undefined
      ) {
        return Number(selectedProject.value.timeLeft).toFixed(2);
      }
      return null;
    });

    const formattedAllocation = computed(() => {
      if (
        selectedProject.value &&
        selectedProject.value.timeAllocation !== null &&
        selectedProject.value.timeAllocation !== undefined
      ) {
        return Number(selectedProject.value.timeAllocation).toFixed(2);
      }
      return null;
    });

    function validateMinutes() {
      if (
        parseInt(minutesElapsed.value) > 59 ||
        parseInt(minutesElapsed.value) < 0
      ) {
        minutesElapsed.value = null;
      }
    }

    function validateSeconds() {
      if (
        parseInt(secondsElapsed.value) > 59 ||
        parseInt(secondsElapsed.value) < 0
      ) {
        secondsElapsed.value = null;
      }
    }

    const allFieldsCompleted = computed(() => {
      const projectCompleted = isOtherProjectSelected.value
        ? otherProjectName.value.trim() !== ""
        : projectNameOption.value !== null;

      if (statusOption.value === "Complete") {
        return (
          projectCompleted &&
          activityDescription.value.trim() !== "" &&
          dateRequested.value !== null &&
          statusOption.value !== null &&
          priorityOption.value !== null &&
          devAssignedOption.value !== null &&
          hoursElapsed.value !== null &&
          minutesElapsed.value !== null &&
          secondsElapsed.value !== null &&
          hoursElapsed.value.length > 0 &&
          minutesElapsed.value.length > 0 &&
          secondsElapsed.value.length > 0
        );
      } else {
        return (
          projectCompleted &&
          activityDescription.value.trim() !== "" &&
          dateRequested.value !== null &&
          statusOption.value !== null &&
          priorityOption.value !== null &&
          devAssignedOption.value !== null
        );
      }
    });

    async function addTakeawayTemplate() {
      loading.value = true;
      const dataToSend = {
        activityDescription: activityDescription.value,
        createdDate: dateRequested.value,
        priority: priorityOption.value,
        projectName: isOtherProjectSelected.value
          ? otherProjectName.value
          : projectNameOption.value,
        status: statusOption.value,
        SE: devAssignedOption.value,
        workingToday: workingToday.value,
        requesterName: isOtherProjectSelected.value
          ? null
          : selectedProject.value.contact,
        customer: isOtherProjectSelected.value
          ? null
          : selectedProject.value.customer,
      };

      if (hoursElapsed.value && hoursElapsed.value > 0) {
        dataToSend.hoursElapsed = hoursElapsed.value;
      }
      if (minutesElapsed.value && minutesElapsed.value > 0) {
        dataToSend.minutesElapsed = minutesElapsed.value;
      }
      if (secondsElapsed.value && secondsElapsed.value > 0) {
        dataToSend.secondsElapsed = secondsElapsed.value;
      }

      await store.dispatch("saveTakeaway", dataToSend);

      resetReferences();
      loading.value = false;
    }

    function resetReferences() {
      projectNameOption.value = null;
      isOtherProjectSelected.value = false;
      otherProjectName.value = "";
      activityDescription.value = "";
      workingToday.value = false;
      dateRequested.value = new Date();
      statusOption.value = "In Progress";
      priorityOption.value = null;
      devAssignedOption.value = null;
      hoursElapsed.value = null;
      minutesElapsed.value = null;
      secondsElapsed.value = null;
    }

    return {
      projectNameOption,
      isOtherProjectSelected,
      otherProjectName,
      activityDescription,
      workingToday,
      dateRequested,
      statusOption,
      priorityOption,
      devAssignedOption,
      hoursElapsed,
      minutesElapsed,
      secondsElapsed,
      loading,
      allFieldsCompleted,
      addTakeawayTemplate,
      statusTakwaways,
      customers,
      projectName,
      requester,
      priority,
      devAssigned,
      validateNumericInput,
      validateMinutes,
      validateSeconds,
      projectNames,
      selectedProject,
      teammates,
      formattedTime,
      formattedAllocation,
      hasTimeLeft,
      hasTimeAllocation,
    };
  },
};
</script>

<style scoped>
/* Utiliza selectores más específicos */
.v-list-item__content {
  color: rgb(var(--v-theme-primary)) !important;
}
.v-list .v-list-item--active {
  background-color: rgb(var(--v-theme-secondary)) !important;
  color: white !important;
}
.date-picker {
  display: flex;
  justify-content: center;
}
</style>
