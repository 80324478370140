<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <CircularLoader v-if="loading"></CircularLoader>
    <v-card v-else>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>

      <v-select
        v-if="selectFlag"
        :items="itemsSelect"
        label="Select status"
        v-model="selectedItem"
      >
      </v-select>

      <v-textarea
        v-if="commentsFlag && showComments"
        label="Comments"
        v-model="comments"
        rows="4"
      ></v-textarea>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel">{{
          cancelButtonText
        }}</v-btn>
        <v-btn color="secondary" text @click="confirm">{{
          confirmButtonText
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script>
import { ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import CircularLoader from "./CircularLoader.vue";

export default {
  components: {
    CircularLoader,
  },
  props: {
    title: String,
    cancelButtonText: {
      type: String,
      default: "Cancelar",
    },
    confirmButtonText: {
      type: String,
      default: "Confirmar",
    },
    value: Boolean,
    action: String,
    selectFlag: Boolean,
    itemsSelect: Array,
    from: String,
    commentsFlag: Boolean,
  },
  setup(props, { emit }) {
    const dialog = ref(false);
    const question = ref("");
    const store = useStore();
    const selectedItem = ref("");
    const router = useRouter();
    const loading = ref(false);
    const comments = ref("");
    const showComments = ref(false);

    watch(
      () => props.value,
      (newValue) => {
        dialog.value = newValue;
      }
    );

    watch(selectedItem, (newValue) => {
      if (newValue === "Complete") {
        showComments.value = true;
      } else {
        showComments.value = false;
      }
    });

    const cancel = () => {
      dialog.value = false;
      emit("update:value", false);
      selectedItem.value = "";
      comments.value = "";
    };

    const confirm = async () => {
      loading.value = true;
      if (props.action == "deleteTakeaway") {
        await store.dispatch(props.action, props.from);
      } else {
        await store.dispatch(props.action, {
          take: selectedItem.value,
          comments: comments.value,
          cleanSeconds: true,
        });
        await store.dispatch("stopTimer");
        // store.commit("clearIsTimerActive");
      }
      dialog.value = false;
      emit("update:value", false);
      selectedItem.value = "";
      comments.value = "";

      if (props.action == "deleteTakeaway") {
        await store.dispatch("getTakewaysAct");
        loading.value = false;
        router.push({ name: "t-t" });
      } else {
        loading.value = false;
        router.push({ name: "user-takeaways" });
      }
    };

    return {
      dialog,
      question,
      cancel,
      confirm,
      selectedItem,
      comments,
      loading,
      showComments,
    };
  },
};
</script>
