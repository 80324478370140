<template>
  <v-app id="backGND">
    <div>
      <CircularLoader v-if="loading"></CircularLoader>
      <FormNT v-else />
    </div>
  </v-app>
</template>
  
<script>
import FormNT from "@/components/FormNewTakeaways.vue";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import CircularLoader from "../components/CircularLoader.vue";

export default {
  components: {
    FormNT,
    CircularLoader,
  },
  setup() {
    const loading = ref(false);
    const store = useStore();

    onMounted(async () => {
      loading.value = true;

      
      if (store.state.project.projects.length === 0) {
        await store.dispatch("getActiveProjects");
      }
      if (store.state.project.teammates.length === 0) {
        await store.dispatch("getTeammates");
      }

      loading.value = false;
    });

    return {
      loading,
    };
  },
};
</script>

<style scoped>
#backGND {
  background-image: url("../../public/3.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: auto;
}
</style>