import user from './user'
import takeaway from './takeaway'
import app from './app'
import tracker from './tracker'
import project from './project'
const worker = new Worker('/timeWorker.js')

export default {
  user,
  takeaway,
  app,
  tracker,
  project,
  worker
}