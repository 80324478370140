import user from './user'
import takeaway from './takeaway'
import app from './app'
import tracker from './tracker'
import project from './project'

export default {
  ...user,
  ...takeaway,
  ...app,
  ...tracker,
  ...project
}