import api from "../../api";

export default {
  async getActiveProjects({ commit }) {
    try {
      const response = await api.get("projects/get-projects")
      commit("setCurrentProjects", response)
    } catch (error) {
      console.error("Error en la solicitud:", error)
      throw error
    }
  },
  async getTeammates({ commit }) {
    try {
      const response = await api.get("projects/get-teammates")
      commit("setTeammates", response)
    } catch (error) {
      console.error("Error en la solicitud:", error)
      throw error
    }
  },
  async updateAllocatedTime(_, data){
    try {
      await api.post("projects/update-left-hours", data);
    } catch (error) {
      console.error("Error en la solicitud:", error);
      throw error;
    }
  }

};
