<template>
  <div>
    <link
      href="https://fonts.googleapis.com/css2?family=Edu+NSW+ACT+Foundation:wght@400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Workbench&display=swap"
      rel="stylesheet"
    />
    <v-app v-if="$store.state.appLoaded === true">
      <AppBar />
      <v-main>
        <router-view />
      </v-main>
      <SnackBar />
      <GlobalTimer v-if="shouldShowGlobalTimer" />
    </v-app>
    <v-app v-else>
      <CircularLoader />
    </v-app>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import AppBar from "./components/ApplicationDrawer.vue";
import SnackBar from "./components/SnackBar.vue";
import CircularLoader from "./components/CircularLoader.vue";
import GlobalTimer from "./components/GlobalTimer.vue";


export default {
  components: {
    AppBar,
    SnackBar,
    CircularLoader,
    GlobalTimer,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const trackerRunning = computed(() => store.getters.isTimerActive);
    

    const shouldShowGlobalTimer = computed(() => {
      const excludedRoute = "/t-t";
      return trackerRunning.value && route.path !== excludedRoute;
    });

    
    return {
      shouldShowGlobalTimer,
    };
  },
};
</script>

<style>
#app {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.v-field__input {
  color: rgb(var(--v-theme-primary)) !important;
}
.v-field--variant-filled {
  color: rgb(var(--v-theme-primary)) !important;
}
.v-select .v-field .v-field__input {
  background-color: #cde2ff !important;
}
.v-field.v-field--appended {
  background-color: #cde2ff !important;
}
</style>
