<template>
  <div>
    <d-modal
      :title="modalTitle"
      cancel-button-text="Cancel"
      confirm-button-text="Confirm"
      v-model:value="modalOpen"
      :action="modalAction"
      :selectFlag="true"
      :itemsSelect="statusTakwaways"
      :from="fromModal"
      :commentsFlag="true"
    >
    </d-modal>
    <div
      v-if="isTimerActive || elapsedTimeOnlySeconds > 0"
      :class="['global-timer', { 'global-timer--expanded': drawer, 'global-timer--collapsed': !drawer }]"
    >
      <v-btn icon @click="toggleCollapse">
        <v-icon>{{
          isCollapsed ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
      </v-btn>
      <div v-show="isCollapsed">
        <h2>
          Elapsed Time:
          {{ formatTime(elapsedTimeOnlySeconds).elapsedHours }}h
          {{ formatTime(elapsedTimeOnlySeconds).elapsedMinutes }}m
          {{ formatTime(elapsedTimeOnlySeconds).elapsedSeconds }}s
        </h2>
      </div>
      <div v-show="!isCollapsed">
        <div class="padd-1">
          <v-row class="row1">
            <v-card-title class="text-left">
              <v-icon color="white">mdi-book-edit-outline</v-icon>
              <b>Activity - {{ selectedTakeaway.activityDescription }}</b>
            </v-card-title>
          </v-row>
        </div>
        <div class="padd-2">
          <v-card-sub-title>
            <b>Project Name:</b>
            {{ selectedTakeaway.projectName }}
          </v-card-sub-title>
          <br />
          <v-card-sub-title>
            <b>Requester Name:</b>
            {{ selectedTakeaway.requesterName }}
          </v-card-sub-title>
          <br />
          <v-card-sub-title>
            <b>Smartsheet Expert:</b>
            {{
              selectedTakeaway.SE ? selectedTakeaway.SE.name : "N/A"
            }}
          </v-card-sub-title>
          <br />
          <v-card-sub-title>
            <b>Status takeaway:</b>
            {{ selectedTakeaway.status }}
          </v-card-sub-title>
        </div>
        <h2>
          Elapsed Time:
          {{ formatTime(elapsedTimeOnlySeconds).elapsedHours }}h
          {{ formatTime(elapsedTimeOnlySeconds).elapsedMinutes }}m
          {{ formatTime(elapsedTimeOnlySeconds).elapsedSeconds }}s
        </h2>
        <br />
        <v-btn
          @click="startTimer"
          color="rgb(var(--v-theme-secondary))"
          icon="mdi-play"
          v-if="isPaused"
          class="btn-start-pause"
          size="x-large"
        ></v-btn>
        <v-btn
          @click="pauseTimer"
          color="rgb(var(--v-theme-secondary))"
          icon="mdi-pause"
          class="btn-start-pause"
          size="x-large"
          v-else
        ></v-btn>
        <v-btn
          @click="stopTimer"
          color="rgb(var(--v-theme-secondary))"
          icon="mdi-stop"
          class="btn-stop"
          size="x-large"
          v-if="elapsedTimeOnlySeconds > 0"
        ></v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import DModal from "./DesicionModal.vue";
import { statusTakwaways } from "../const";
import { db, auth } from "../firebase/firebaseConfig.js";
import { doc, onSnapshot } from "firebase/firestore";

export default {
  components: {
    DModal,
  },
  setup() {
    const store = useStore();
    const elapsedTimeOnlySeconds = computed(
      () => store.getters.elapsedTimeOnlySeconds
    );
    const selectedTakeaway = computed(() => store.getters.selectedTakeaway);
    const isPaused = ref(false);
    const isTimerActive = computed(() => store.getters.isTimerActive);
    const modalOpen = ref(false);
    const modalTitle = ref(null);
    const modalAction = ref(null);
    const fromModal = ref(null);
    const isCollapsed = ref(false);
    const currentUser = auth.currentUser;
    const userTimerDocRef = doc(db, "user-timer", currentUser.uid);
    const stopPushed = ref(null);
    const drawer = computed(() => store.getters.drawer);

    const formatTime = (seconds) => {
      const elapsedHours = Math.floor(seconds / 3600);
      const elapsedMinutes = Math.floor((seconds % 3600) / 60);
      const elapsedSeconds = seconds % 60;
      return { elapsedHours, elapsedMinutes, elapsedSeconds };
    };

    const startTimer = async () => {
      await store.dispatch("startTimer", {
        startWithTime: elapsedTimeOnlySeconds.value,
        ticket: selectedTakeaway.value.ticket,
      });
      await store.dispatch("updateElapsedTime");
    };

    const pauseTimer = async () => {
      await store.dispatch("pauseTimer", { fromButton: true });
    };

    const stopTimer = async () => {
      stopPushed.value = true;
      const previousElapsedTime = elapsedTimeOnlySeconds.value;

      const data = {
        ticket: selectedTakeaway.value.ticket,
        elapsedHours: formatTime(previousElapsedTime).elapsedHours,
        elapsedMinutes: formatTime(previousElapsedTime).elapsedMinutes,
        elapsedSeconds: formatTime(previousElapsedTime).elapsedSeconds,
        isGlobal: true,
        fromButton: true,
      };

      modalTitle.value = "Please select the status for this activity";
      modalAction.value = "updateTracker";
      fromModal.value = "stop";
      modalOpen.value = true;
      await store.dispatch("pauseTimer", data);
    };

    const toggleCollapse = () => {
      isCollapsed.value = !isCollapsed.value;
    };

    onMounted(() => {
      if (isTimerActive.value == true) {
        isPaused.value = false;
      }
    });

    onSnapshot(userTimerDocRef, async (doc) => {
      console.log("Se produjo un cambio en el documento de Firestore GlobalTimer.vue");
      if (doc.exists()) {
        const dataDoc = doc.data();
        const isNewPausedState = dataDoc.isTimerPaused;
        isPaused.value = isNewPausedState;

        if (dataDoc.stop) {
          isPaused.value = true;
          await store.dispatch("pauseTimer", { firestoreFlag: true });
          return;
        }
        if (!isNewPausedState) {
          await store.dispatch("startTimer", {
            startWithTime: elapsedTimeOnlySeconds.value,
            ticket: selectedTakeaway.value.ticket,
            firestoreFlag: true,
          });
          await store.dispatch("updateElapsedTime");
        } else {
          await store.dispatch("pauseTimer", { firestoreFlag: true });
        }
      }
    });

    return {
      elapsedTimeOnlySeconds,
      isPaused,
      formatTime,
      selectedTakeaway,
      pauseTimer,
      startTimer,
      stopTimer,
      isTimerActive,
      modalOpen,
      modalTitle,
      modalAction,
      statusTakwaways,
      fromModal,
      isCollapsed,
      toggleCollapse,
      drawer,
    };
  },
};
</script>

<style scoped>
.global-timer {
  position: fixed;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  border-radius: 5px;
  transition: left 0.3s; /* Añade una transición suave */
}
.global-timer--expanded {
  left: 250px;
}
.global-timer--collapsed {
  left: 10px;
}
.padd-1 {
  background-color: rgb(var(--v-theme-accent));
  padding: 1rem 4rem 1rem 4rem;
  align-items: center;
  display: flex;
  border-radius: 0rem 0rem 0.5rem 0.5rem !important;
}
.padd-2 {
  padding: 2rem 4rem 2rem 4rem;
  background-color: rgb(var(--v-theme-secondary));
  text-align: left;
  color: rgb(var(--v-theme-primary));
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem !important;
}
</style>