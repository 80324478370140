<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <div v-if="showLoader">
      <Loader />
    </div>
    <v-container v-else>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            hide-details
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="showPassword = !showPassword"
            label="Password"
            hide-details
            required
            :type="showPassword ? 'text' : 'password'"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn type="submit" block class="mt-2">Login</v-btn>
    </v-container>
  </v-form>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Loader from "../components/CircularLoader.vue";
export default {
  components: {
    Loader,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const valid = ref(false);
    const email = ref("");
    const password = ref("");
    const showPassword = ref(false);

    const emailRules = [
      (value) => {
        if (value) return true;
        return "E-mail is required.";
      },
      (value) => {
        if (/.+@.+\..+/.test(value)) return true;
        return "E-mail must be valid.";
      },
    ];
    const showLoader = computed(() => store.state.app.loader);
    const passwordRules = [
      (value) => {
        if (value) return true;
        return "Password is required.";
      },
      (value) => {
        if (value?.length >= 6) return true;
        return "Password must be 6 or more characters.";
      },
    ];

    const submit = async () => {
      const data = {
        email: email.value,
        password: password.value,
      };

      try {
        await store.dispatch("setUserAct", data);
      } catch (error) {
        console.error("Error during login:", error);
      }
    };

    onMounted(() => {
      if (store.state.loggedUser) {
        router.push({ name: "user-takeaways" });
      }
    });

    return {
      valid,
      email,
      emailRules,
      password,
      passwordRules,
      showLoader,
      submit,
      showPassword
    };
  },
};
</script>
