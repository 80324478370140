import store from "@/store";
import router from "@/router";
export default {
  async get(endpoint, params) {
    try {
      let url = "";
      if (params != undefined) {
        url = `${endpoint}/${params}`;
      } else {
        url = `${endpoint}`;
      }
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 403) {
        const responseData = await response.json();
        if (responseData.error === "Expired Token") {
          await store.dispatch("logOut");
          router.push({ name: "login" });
        }
      }

      return await response.json();
    } catch (error) {
      console.error("Error in request:", error);
      throw error;
    }
  },

  async post(endpoint, data) {
    try {
      const response = await fetch(`${endpoint}`, {
        method: "POST",
        credentials: "include",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.status === 403) {
        const responseData = await response.json();
        if (responseData.error === "Expired Token") {
          await store.dispatch("logOut");
          router.push({ name: "login" });
        }
      }
      if (!response.ok) {
        throw new Error(`Error performing request: ${response.statusText}`);
      }
      return await response.json();
    } catch (error) {
      console.error("Error in request:", error);
      throw error;
    }
  },

  async put(endpoint, data) {
    try {
      const response = await fetch(`${endpoint}`, {
        method: "PUT",
        credentials: "include",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.status === 403) {
        const responseData = await response.json();
        if (responseData.error === "Expired Token") {
          await store.dispatch("logOut");
          router.push({ name: "login" });
        }
      }
      if (!response.ok) {
        throw new Error(`Error performing request: ${response.statusText}`);
      }
      return await response.json();
    } catch (error) {
      console.error("Error in request:", error);
      throw error;
    }
  },
  async delete(endpoint, params) {
    try {
      let url = "";
      if (params != undefined) {
        url = `${endpoint}/${params}`;
      } else {
        url = `${endpoint}`;
      }
      const response = await fetch(url, {
        method: "DELETE",
        credentials: "include",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 403) {
        const responseData = await response.json();
        if (responseData.error === "Expired Token") {
          await store.dispatch("logOut");
          router.push({ name: "login" });
        }
      }
      if (!response.ok) {
        throw new Error(`Error performing request: ${response.statusText}`);
      }
      return await response.json();
    } catch (error) {
      console.error("Error in request:", error);
      throw error;
    }
  },
};
