<template>
  <v-app id="backGND">
    <div v-if="routeParams">
      <TT
        v-if="totalSeconds !== null && totalSeconds !== undefined"
        :takeaway="routeParams"
        isFromUrl="true"
        :totalSeconds="totalSeconds"
      />
    </div>
    <div v-else class="">
      <div v-if="selectedItem === null || totalSeconds === null">
        <v-select
          class="div-select"
          :items="items"
          item-title="activityDescription"
          item-value="ticket"
          label="Takeways"
          v-model="selectedItem"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :subtitle="item.raw.projectName"
            ></v-list-item>
          </template>
        </v-select>
      </div>
      <div v-else>
        <v-select
          class="div-select"
          :items="items"
          item-title="activityDescription"
          item-value="ticket"
          label="Takeways"
          v-model="selectedItem"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :subtitle="item.raw.projectName"
            ></v-list-item>
          </template>
        </v-select>
        <br /><br />
        <TT
          v-if="flagTT"
          :takeaway="selectedItemObject"
          isFromUrl="false"
          :totalSeconds="totalSeconds"
        />
      </div>
    </div>
  </v-app>
</template>

<script>
import TT from "../components/TimeTracker.vue";
import { ref, onMounted, computed, watch, onBeforeUnmount } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  components: {
    TT,
  },
  setup() {
    const route = useRoute();
    const routeParams = ref(null);
    const store = useStore();
    const items = ref([]);
    const selectedItem = ref(null);
    const totalSeconds = ref(null);
    const flagTT = ref(null);
    const isTimerActive = computed(() => store.getters.isTimerActive);
    const selectedTakeaway = computed(() => store.getters.selectedTakeaway);
    const elapsedTimeOnlySeconds = computed(
      () => store.getters.elapsedTimeOnlySeconds
    );
    onMounted(async () => {
      if (store.state.takeaway.takeaways) {
        items.value = Object.values(store.state.takeaway.takeaways);
      } else {
        await store.dispatch("getTakewaysAct");
        items.value = Object.values(store.state.takeaway.takeaways);
      }
      if (isTimerActive.value) {
        routeParams.value = true;
        totalSeconds.value = elapsedTimeOnlySeconds;
      } else {
        const paramsString = route.query && route.query.item;

        if (paramsString) {
          try {
            routeParams.value = JSON.parse(paramsString);
            selectedItem.value = routeParams.value.item.ticket;
            await store.dispatch("getTracker", {
              ticket: routeParams.value.item.ticket,
            });
            if (store.state.tracker.elapsedTime) {
              const { elapsedHours, elapsedMinutes, elapsedSeconds } =
                store.state.tracker.elapsedTime;

              const totalSeconds2 =
                parseInt(elapsedHours) * 3600 +
                parseInt(elapsedMinutes) * 60 +
                parseInt(elapsedSeconds);

              totalSeconds.value = totalSeconds2;
              store.dispatch("setElapsedTimeOnlySeconds", totalSeconds2);
              console.log("totalSeconds.value:", totalSeconds.value);
            } else {
              store.dispatch("setElapsedTimeOnlySeconds", 0);
              totalSeconds.value = 0;
            }
          } catch (error) {
            console.error("Error parsing parameters:", error);
          }
        }
      }
    });

    onBeforeUnmount(() => {
      store.commit("clearTakeawayMut");
    });

    const selectedItemObject = computed(() => {
      if (!selectedItem.value) return null;
      return items.value.find((item) => item.ticket === selectedItem.value);
    });

    watch(selectedItem, async (newValue, oldValue) => {
      console.log("🚀 ~ selectedItem ha cambiado de", oldValue, "a", newValue);
      flagTT.value = false;
      await store.dispatch("clearElapsedTime");
      await store.dispatch("getTracker", { ticket: newValue });
      const selectedTakeaway = items.value.find(
        (take) => take.ticket == newValue
      );
      console.log("🚀 ~ watch ~ selectedTakeaway:", selectedTakeaway)

      await store.commit("setSelectedTakeaway", selectedTakeaway);
      if (store.state.tracker.elapsedTime) {
        const { elapsedHours, elapsedMinutes, elapsedSeconds } =
          store.state.tracker.elapsedTime;

        const totalSeconds2 =
          parseInt(elapsedHours) * 3600 +
          parseInt(elapsedMinutes) * 60 +
          parseInt(elapsedSeconds);

        totalSeconds.value = totalSeconds2;
      } else {
        store.commit("setElapsedTimeOnlySeconds", 0)
        totalSeconds.value = 0;
      }
      flagTT.value = true;
    });

    return {
      routeParams,
      items,
      selectedItemObject,
      selectedItem,
      totalSeconds,
      flagTT,
      isTimerActive,
      selectedTakeaway,
    };
  },
};
</script>
<style scoped>
#backGND {
  background-image: url("../../public/2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: auto;
}
.mid {
  display: flex;
  justify-content: center !important;
}
.div-select {
  width: 50%;
  margin: 0 auto; /* Esto centra el elemento horizontalmente */
}
</style>