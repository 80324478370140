<template>
  <!-- <v-app-bar color="primary" app>
    <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
    <v-toolbar-title style="color: #93b5c6; font-weight: 600"
      >Kambuchi T-T</v-toolbar-title
    >
  </v-app-bar> -->
  <v-app-bar color="primary" app dense>
    <v-app-bar-nav-icon @click="changeDrawer"></v-app-bar-nav-icon>
    <img src="../../public/Kambuchi31.png" alt="Logo" class="app-logo" />
  </v-app-bar>

  <v-navigation-drawer color="secondary" app v-model="drawer">
    <v-list base-color="primary">
      <v-list-item v-if="isAuthenticated" @click="goUserTakeaways">
        <v-row align="center">
          <v-col cols="auto">
            <v-list-item-icon>
              <v-icon class="padd-left">mdi-book-edit</v-icon>
            </v-list-item-icon>
          </v-col>
          <v-col class="text-left">
            <v-list-item-content>
              <v-list-item-title>My Takeaways</v-list-item-title>
            </v-list-item-content>
          </v-col>
        </v-row>
      </v-list-item>
      <v-divider />
      <v-list-item v-if="isAuthenticated" @click="goTT">
        <v-row align="center">
          <v-col cols="auto">
            <v-list-item-icon>
              <v-icon class="padd-left">mdi-timer-sand</v-icon>
            </v-list-item-icon>
          </v-col>
          <v-col class="text-left">
            <v-list-item-content>
              <v-list-item-title> My Time Tracker </v-list-item-title>
            </v-list-item-content>
          </v-col>
        </v-row>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item v-if="isAuthenticated" @click="goNewTakeaway">
        <v-row align="center">
          <v-col cols="auto">
            <v-list-item-icon>
              <v-icon class="padd-left">mdi-archive-plus</v-icon>
            </v-list-item-icon>
          </v-col>
          <v-col class="text-left">
            <v-list-item-content>
              <v-list-item-title> New Takeaway </v-list-item-title>
            </v-list-item-content>
          </v-col>
        </v-row>
      </v-list-item>
      <v-divider />
      <v-list-item @click="toggleAuthentication">
        <v-row align="center">
          <v-col cols="auto">
            <v-list-item-icon>
              <v-icon class="padd-left">{{
                isAuthenticated ? "mdi-logout" : "mdi-login"
              }}</v-icon>
            </v-list-item-icon>
          </v-col>
          <v-col class="text-left">
            <v-list-item-content>
              <v-list-item-title>
                {{ isAuthenticated ? "Log Out" : "Log In" }}
              </v-list-item-title>
            </v-list-item-content>
          </v-col>
        </v-row>
      </v-list-item>
      <v-list-item v-if="!isAuthenticated" @click="goRegisterUser">
        <v-row align="center">
          <v-col cols="auto">
            <v-list-item-icon>
              <v-icon class="padd-left">mdi-archive-plus</v-icon>
            </v-list-item-icon>
          </v-col>
          <v-col class="text-left">
            <v-list-item-content>
              <v-list-item-title> Register User </v-list-item-title>
            </v-list-item-content>
          </v-col>
        </v-row>
      </v-list-item>
      <v-divider />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  setup() {
    const drawer = computed(() => store.getters.drawer);
    const store = useStore();
    const router = useRouter();

    const toggleAuthentication = async () => {
      if (isAuthenticated.value) {
        await store.dispatch("logOut");
        router.push({ name: "login" });
      } else {
        router.push({ name: "login" });
      }
    };

    const goUserTakeaways = async () => {
      router.push({ name: "user-takeaways" });
    };

    const goTT = async () => {
      router.push({ name: "t-t" });
    };

    const goNewTakeaway = async () => {
      router.push({ name: "add-takeaway" });
    };

    const goRegisterUser = async () => {
      router.push({ name: "register" });
    };

    const isAuthenticated = computed(() => store.state.loggedUser);

    const changeDrawer = async () => {
      drawer.value
        ? await store.commit("clearDrawer")
        : await store.commit("setDrawer");
    };

    return {
      changeDrawer,
      toggleAuthentication,
      isAuthenticated,
      goUserTakeaways,
      goTT,
      goNewTakeaway,
      goRegisterUser,
      drawer,
    };
  },
};
</script>

<style scoped>
.padd-left {
  padding-left: 3rem;
}
.text-left {
  text-align: left;
}
img {
  width: 5rem;
  height: 3.5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>