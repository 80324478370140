<template>
    <div class="text-center">
      <v-snackbar v-model="localSnackbar.enabled" multi-line>
        {{ localSnackbar.text }}
  
        <template v-slot:actions>
          <v-btn color="red" variant="text" @click="closeSnackbar">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        localSnackbar: {
          text: '',
          enabled: false,
        },
      };
    },
    watch: {
      'snackbar.enabled'(newVal) {
        this.localSnackbar.enabled = newVal;
      },
      'snackbar.text'(newVal) {
        this.localSnackbar.text = newVal;
      },
    },
    methods: {
      closeSnackbar() {
        this.$store.commit('clearSnackbar');
        this.localSnackbar.enabled = false;
      },
    },
    computed: {
      snackbar: {
        get() {
          return this.$store.state.app.snackbar;
        },
        set(value) {
          this.$store.commit('setSnackbar', value);
        },
      },
    },
  };
  </script>
  